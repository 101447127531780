import React, { useContext } from 'react';
import {
  FormFieldVariant,
  FormInputFieldInputType
} from '@aurora/shared-client/components/form/enums';
import { useClassNameMapper } from 'react-bootstrap';
import type { FieldPath, FieldValues } from 'react-hook-form';
import type { NodeActionButtonProps } from '../NodeActionButtonWidget/NodeActionButtonWidget';
import { ButtonSize } from '../NodeActionButtonWidget/NodeActionButtonWidget';
import useTranslation from '../../useTranslation';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { InputFieldSpec } from '@aurora/shared-client/components/form/InputField/InputField';
import type { ConfigurationSpec } from '../../common/Widget/types';
import formSchema from './NodeActionButtonWidgetEditor.form.json';
import type { Form } from '@aurora/shared-generated/types/graphql-schema-types';
import PageEditorWidgetSidebarForm from '../../pageeditor/PageEditorWidgetSidebarForm/PageEditorWidgetSidebarForm';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import type { PillRadioSpec } from '@aurora/shared-client/components/form/PillRadioField/PillRadioField';
import Icons from '@aurora/shared-client/icons';
import { useActionButtonWidgetFinalProps } from '../NodeActionButtonWidget/NodeActionButtonWidgetHelper';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type { IconRadioSpec } from '@aurora/shared-client/components/form/IconRadioField/IconRadioField';
import localStyles from './NodeActionButtonEditor.module.pcss';

interface ConfigurationFormData extends FieldValues {
  actionButtonTitle: string;
  buttonAlignment: TextAlignment;
  buttonWidth: ButtonSize;
}

export enum ButtonStyle {
  LARGE = 'lg',
  SMALL = 'sm',
  MEDIUM = 'md'
}

const NodeActionButtonEditor: React.FC<React.PropsWithChildren<NodeActionButtonProps>> = props => {
  const [propsLoading, finalProps] = useActionButtonWidgetFinalProps(props);
  const cx = useClassNameMapper(localStyles);
  const editorI18n = useTranslation(EndUserComponent.NODE_ACTION_BUTTON_WIDGET_EDITOR);
  const { contextNode } = useContext(AppContext);
  const { actionButtonTitle, instanceId } = finalProps;
  function getConfigurationSpec() {
    const buttonStyleField: IconRadioSpec<'buttonStyle', ConfigurationFormData> = {
      name: 'buttonStyle',
      fieldVariant: FormFieldVariant.ICON_RADIO,
      defaultValue: finalProps.buttonStyle,
      values: [
        {
          key: ButtonStyle.SMALL,
          value: ButtonStyle.SMALL,
          icon: Icons.ButtonStandardIcon
        },
        {
          key: ButtonStyle.MEDIUM,
          value: ButtonStyle.MEDIUM,
          icon: Icons.ButtonLargeIcon
        },
        {
          key: ButtonStyle.LARGE,
          value: ButtonStyle.LARGE,
          icon: Icons.ButtonHeroIcon
        }
      ]
    };
    const actionButtonTitleField: InputFieldSpec<'actionButtonTitle', ConfigurationFormData> = {
      name: 'actionButtonTitle',
      fieldVariant: FormFieldVariant.INPUT,
      defaultValue: actionButtonTitle,
      inputType: FormInputFieldInputType.TEXT,
      isVisible: {
        watchFields: null,
        callback: () => {
          return contextNode.nodeType !== NodeType.GROUPHUB;
        }
      }
    };
    const buttonAlignmentField: PillRadioSpec<'buttonAlignment', ConfigurationFormData> = {
      name: 'buttonAlignment',
      fieldVariant: FormFieldVariant.PILL_RADIO,
      defaultValue: finalProps.buttonAlignment,
      formGroupSpec: {
        label: false
      },
      disabled: finalProps.buttonWidth === ButtonSize.FULL_WIDTH,
      className: cx({ 'lia-is-disabled': finalProps.buttonWidth === ButtonSize.FULL_WIDTH }),
      values: [
        {
          key: TextAlignment.LEFT,
          value: TextAlignment.LEFT,
          icon: Icons.TextAlignLeftIcon
        },
        {
          key: TextAlignment.CENTER,
          value: TextAlignment.CENTER,
          icon: Icons.TextAlignCenterIcon
        },
        {
          key: TextAlignment.RIGHT,
          value: TextAlignment.RIGHT,
          icon: Icons.TextAlignRightIcon
        }
      ]
    };
    const buttonWidthField: PillRadioSpec<'buttonWidth', ConfigurationFormData> = {
      name: 'buttonWidth',
      fieldVariant: FormFieldVariant.PILL_RADIO,
      defaultValue: finalProps.buttonWidth,
      values: [
        {
          key: ButtonSize.CONTENT_WIDTH,
          value: ButtonSize.CONTENT_WIDTH
        },
        {
          key: ButtonSize.FULL_WIDTH,
          value: ButtonSize.FULL_WIDTH
        }
      ]
    };
    const watchFields: Array<FieldPath<ConfigurationFormData>> = ['actionButtonTitle'];
    const configurationSpec: ConfigurationSpec<NodeActionButtonProps, ConfigurationFormData> = {
      fieldSpecs: [
        actionButtonTitleField,
        buttonAlignmentField,
        buttonWidthField,
        buttonStyleField
      ],
      componentId: EndUserComponent.NODE_ACTION_BUTTON_WIDGET,
      id: 'NodeActionButtonEditor',
      formSchema: {
        schema: formSchema as Form,
        cx
      },
      instanceId,
      //watch actionButtonTitle and delay by 500ms
      submitOnChange: {
        watchFields
      },
      transformToWidgetProps: (data: ConfigurationFormData): NodeActionButtonProps => {
        const { actionButtonTitle: title } = data;
        const currentProps = {} as NodeActionButtonProps;
        currentProps.actionButtonTitle = title == finalProps.actionButtonTitle ? '' : title;
        currentProps.buttonAlignment = data.buttonAlignment;
        currentProps.buttonWidth = data.buttonWidth;
        currentProps.buttonStyle = data.buttonStyle;
        return currentProps;
      }
    };
    return configurationSpec;
  }
  if (propsLoading) {
    return null;
  }
  return (
    <PageEditorWidgetSidebarForm
      configurationSpec={getConfigurationSpec()}
      editLevel={props.editLevel}
      editorI18n={editorI18n}
    />
  );
};

export default NodeActionButtonEditor;
