import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { useContext } from 'react';
import type { GroupHubDetailsQueryVariables } from '../../../types/graphql-types';
import { getDefaultActionButtonTextKey } from '../../community/NodeBannerWidget/NodeBannerWidgetHelper';
import useGroupHubDetails from '../../grouphubs/useGroupHubDetails';
import { ButtonSize, type NodeActionButtonProps } from './NodeActionButtonWidget';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import useTranslation from '../../useTranslation';
import {
  UndefinedValueMergeBehavior,
  merge
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import { ButtonStyle } from '../NodeActionButtonWidgetEditor/NodeActionButtonEditor';

export function useActionButtonWidgetFinalProps(
  props: NodeActionButtonProps
): [boolean, NodeActionButtonProps] {
  const defaultProps: NodeActionButtonProps = {
    buttonAlignment: TextAlignment.LEFT,
    buttonWidth: ButtonSize.CONTENT_WIDTH,
    buttonStyle: ButtonStyle.SMALL
  };
  const { contextNode } = useContext(AppContext);
  const { nodeType, userContext } = contextNode;
  const { registrationStatus, confirmEmailStatus } = useRegistrationStatus();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NODE_ACTION_BUTTON_WIDGET
  );
  let defaultText, defaultTextKey;

  const variables: GroupHubDetailsQueryVariables = {
    id: contextNode.id,
    useGroupHubDescendants: true,
    useMembershipInformation: true,
    useGroupHubPolicies: true
  };
  const {
    queryResult: { data },
    loading: groupHubInfoLoading
  } = useGroupHubDetails(variables, nodeType !== NodeType.GROUPHUB);

  const groupHub = data?.groupHub;
  if (!props.actionButtonTitle) {
    defaultTextKey = getDefaultActionButtonTextKey(
      contextNode,
      userContext,
      registrationStatus,
      confirmEmailStatus,
      groupHub
    );
    defaultText = formatMessage(defaultTextKey);
  } else {
    defaultText = props.actionButtonTitle;
  }
  const mergedProps: NodeActionButtonProps = merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
  return [
    groupHubInfoLoading || textLoading,
    {
      ...mergedProps,
      actionButtonTitle: defaultText
    }
  ];
}
